import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { TenantType } from "../types/tenant";
import Tabs from "./Tabs";
import UsersTable from "./UsersTable";
import SlideUserForm from "./SlideUserForm";
import envs from "../config/api";

type ISlideTenantDetail = {
  open: boolean;
  onClose: () => void;
  tenant: TenantType;
};

export default function SlideTenantDetail({
  open,
  onClose,
  tenant,
}: ISlideTenantDetail) {
  const [editEnabled, setEditEnabled] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [tabs, setTabs] = useState([
    { name: "Settings", current: true, href: "#" },
    // { name: "Users", current: false, href: "#" },
  ]);

  const handleEditSave = () => {
    if (editEnabled) {
      //TODO HANDLE SAVE
      setEditEnabled(false);
    } else {
      setEditEnabled(true);
    }
  };

  const handleSelectTab = (selectedTab: {
    name: string;
    href: string;
    current: boolean;
  }) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === selectedTab.name,
      }))
    );
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 pb-[40px] z-50">
                        {/* Header */}
                        <div className="bg-gray-50 px-10 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                {tenant?.companyName}
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Press Edit to modify the parameters and tenant
                                information.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={onClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <button onClick={async () => {
                          try{
                            console.log("Resetting checksums");
                            await fetch(`${envs.inventoryBaseUrl}/cron-inventory/updateAllCheckSums`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                "access-control-allow-origin": "*",
                                "access-control-allow-headers": "*",
                              },
                              body: JSON.stringify({
                                tenantId: tenant._id,
                                isTest: true
                              }),
                            }).then((resp) => console.log(JSON.stringify(resp.json(), null, 2))).catch((e) => console.error(e));
                          }
                          catch(e){
                            alert("Error resetting checksums");
                            console.error(e);
                          }
                        }}>Reset Checksums</button>
                        <Tabs tabs={tabs} onSelect={handleSelectTab} />

                        {tabs.find(
                          (tab) => tab.name === "Settings" && tab.current
                        ) && (
                          <SettingsTab
                            editEnabled={editEnabled}
                            tenant={tenant}
                          />
                        )}
                        {tabs.find(
                          (tab) => tab.name === "Users" && tab.current
                        ) && (
                          <></>
                          // <UsersTable onAddUser={() => setShowUserForm(true)} />
                        )}
                      </div>

                      {/* Action buttons */}
                      {tabs.find(
                        (tab) => tab.name === "Settings" && tab.current
                      ) && (
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6 bottom-0 left-0 right-0 bg-white">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={onClose}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => handleEditSave()}
                              type="button"
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              {editEnabled ? "Save" : "Edit"}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                    <SlideUserForm
                      open={showUserForm}
                      onClose={() => setShowUserForm(false)}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const SettingsTab = ({
  tenant,
  editEnabled,
}: {
  tenant: TenantType;
  editEnabled: boolean;
}) => {
  return (
    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
      {/* Tenant name */}
      <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Tenant name
          </label>
        </div>
        <div className="sm:col-span-2">
          <input
            type="text"
            disabled={!editEnabled}
            value={tenant?.companyName}
            name="project-name"
            id="project-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
          />
        </div>
      </div>

      {/* Tenant email */}
      <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Email
          </label>
        </div>
        <div className="sm:col-span-2">
          <input
            type="text"
            disabled={!editEnabled}
            value={tenant?.email}
            name="project-name"
            id="project-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
          />
        </div>
      </div>

      {/* Project description */}
      <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="project-description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Description
          </label>
        </div>
        <div className="sm:col-span-2">
          <textarea
            id="project-description"
            name="project-description"
            rows={3}
            disabled={!editEnabled}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
            defaultValue={""}
          />
        </div>
      </div>
      <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <legend className="sr-only">Status</legend>
        <div
          className="text-sm font-medium leading-6 text-gray-900"
          aria-hidden="true"
        >
          Status
        </div>
        <div className="space-y-5 sm:col-span-2">
          <div className="space-y-5 sm:mt-0">
            <div className="relative flex items-start">
              <div className="absolute flex h-6 items-center">
                <input
                  id="public-access"
                  name="privacy"
                  aria-describedby="public-access-description"
                  type="radio"
                  disabled={!editEnabled}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed disabled:text-gray-500 disabled:ring-gray-200"
                  defaultChecked
                />
              </div>
              <div className="pl-7 text-sm leading-6">
                <label
                  htmlFor="public-access"
                  className="font-medium text-gray-900"
                >
                  Active
                </label>
                <p id="public-access-description" className="text-gray-500">
                  All accesses are enabled
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="absolute flex h-6 items-center">
                <input
                  id="restricted-access"
                  disabled={!editEnabled}
                  name="privacy"
                  aria-describedby="restricted-access-description"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed disabled:text-gray-500 disabled:ring-gray-200"
                />
              </div>
              <div className="pl-7 text-sm leading-6">
                <label
                  htmlFor="restricted-access"
                  className="font-medium text-gray-900"
                >
                  Suspended
                </label>
                <p id="restricted-access-description" className="text-gray-500">
                  Access temporarily disabled to users
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="absolute flex h-6 items-center">
                <input
                  id="private-access"
                  disabled={!editEnabled}
                  name="privacy"
                  aria-describedby="private-access-description"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed disabled:text-gray-500 disabled:ring-gray-200"
                />
              </div>
              <div className="pl-7 text-sm leading-6">
                <label
                  htmlFor="private-access"
                  className="font-medium text-gray-900"
                >
                  Deleted
                </label>
                <p id="private-access-description" className="text-gray-500">
                  Access to all services permanently disabled
                </p>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <legend className="sr-only">Applications</legend>
        <div
          className="text-sm font-medium leading-6 text-gray-900"
          aria-hidden="true"
        >
          Applications
        </div>
        <div className="space-y-5 sm:col-span-2">
          <div className="space-y-5 sm:mt-0">
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Inventory
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Order
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Orders in
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Orders out
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Stockcheck
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Capacity dashboard
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  RFID
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  disabled={!editEnabled}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  UKHO
                </label>
                {/* <p className="mt-1 text-gray-600">
              Get notified when someones posts a comment
              on a posting.
            </p> */}
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
        <div>
          <a
            href="#"
            className="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900"
          >
            <LinkIcon
              className="h-5 w-5 text-indigo-500 group-hover:text-indigo-900"
              aria-hidden="true"
            />
            <span>Copy link</span>
          </a>
        </div>
        <div>
          <a
            href="#"
            className="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900"
          >
            <QuestionMarkCircleIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span>Learn more about sharing</span>
          </a>
        </div>
      </div> */}
        </div>
      </fieldset>
    </div>
  );
};
