

console.log({processEnv:process.env.REACT_APP_TENANT_BASE_DOMAIN})
const {REACT_APP_INVENORTY_BASE_URL,REACT_APP_COGNITO_BASE_DOMAIN, REACT_APP_TENANT_BASE_DOMAIN, REACT_APP_COGNITO_CLIENT_ID, REACT_APP_COGNITO_USER_POOL_ID} = process.env;
const envs = {
  cognitoBaseDomain: REACT_APP_COGNITO_BASE_DOMAIN,
  tenantBaseDomain: REACT_APP_TENANT_BASE_DOMAIN,
  cognitoClientId: REACT_APP_COGNITO_CLIENT_ID,
  cognitoUserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  inventoryBaseUrl: REACT_APP_INVENORTY_BASE_URL
};
export default envs;
