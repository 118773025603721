import {
  GeneralContextProvider,
  generalContextInitialValue,
} from "./context/App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import IsLogin from "./components/IsLogin";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // default theme
import envs from "./config/api"
// Amplify.configure({
//   Auth: {
//     Cognito: {
//       userPoolClientId: "69gnqerkb2l27tvno34cjjmn2p",
//       userPoolId: "eu-west-1_2Fm69Hykd",
//     },
//   },
// });

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: envs.cognitoClientId!,
      userPoolId: envs.cognitoUserPoolId!,
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <GeneralContextProvider
      generalContextInitialValue={generalContextInitialValue}
    >
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <IsLogin />
        </Authenticator.Provider>
      </QueryClientProvider>
    </GeneralContextProvider>
  );
}

export default App;
